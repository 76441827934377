<div>
  <button
    *ngIf="avalabileLanguages?.length"
    mat-button
    class="language-button"
    [matMenuTriggerFor]="languageMenu"
    #trigger="matMenuTrigger"
    [attr.aria-expanded]="trigger.menuOpened"
  >
    <div class="inner-wrapper">
      <span class="iso" name="language-switcher">{{ currentLanguage }}</span>
      <mat-icon>{{ 'keyboard_arrow_' + (trigger?.menuOpen ? 'up' : 'down') }}</mat-icon>
    </div>
  </button>

  <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
    <button
      mat-menu-item
      class="menu-item-button"
      [class.selected-button]="lang === currentLanguage"
      *ngFor="let lang of avalabileLanguages"
      (click)="setLanguage(lang)"
      aria-labelledby="language-switcher"
      role="menuitem"
    >
      <span fxLayout="row" fxLayoutAlign="start center">
        <!--<img class="flag" [src]="'assets/images/flags/' + lang + '.png'" aria-hidden="true" />-->
        <span class="iso" name="language-switcher" role="">{{ lang }}</span>
      </span>
    </button>
  </mat-menu>
</div>
