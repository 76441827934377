export const Markers = {
  whiteMarker: `<svg
     xmlns:dc="http://purl.org/dc/elements/1.1/"
     xmlns:cc="http://creativecommons.org/ns#"
     xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
     xmlns:svg="http://www.w3.org/2000/svg"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
     xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
     width="20"
     height="28"
     viewBox="0 0 20 28"
     fill="none"
     version="1.1"
     id="svg4"
     sodipodi:docname="white-marker.svg"
     inkscape:version="0.92.5 (2060ec1f9f, 2020-04-08)">
    <metadata
       id="metadata10">
      <rdf:RDF>
        <cc:Work
           rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type
             rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <defs
       id="defs8" />
    <sodipodi:namedview
       pagecolor="#ffffff"
       bordercolor="#666666"
       borderopacity="1"
       objecttolerance="10"
       gridtolerance="10"
       guidetolerance="10"
       inkscape:pageopacity="0"
       inkscape:pageshadow="2"
       inkscape:window-width="1848"
       inkscape:window-height="1025"
       id="namedview6"
       showgrid="false"
       inkscape:zoom="8.4285714"
       inkscape:cx="10.355932"
       inkscape:cy="14"
       inkscape:window-x="72"
       inkscape:window-y="27"
       inkscape:window-maximized="1"
       inkscape:current-layer="svg4" />
    <path
       d="m 9.99984,0.666504 c -5.16,0 -9.333336,4.173336 -9.333336,9.333336 0,6.99996 9.333336,17.33336 9.333336,17.33336 0,0 9.33336,-10.3334 9.33336,-17.33336 0,-5.16 -4.1734,-9.333336 -9.33336,-9.333336 z"
       id="path2"
       aria-label="###arialabel"
       inkscape:connector-curvature="0"
       style="fill:###markerFillColor"
       sodipodi:nodetypes="sscss" />
  </svg>`
};
