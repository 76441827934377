import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import gql from 'graphql-tag';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Config } from '../../config';
import { GraphqlService } from '../../graphql/graphql.service';
import {UserService} from "@users/domain/services/user.service";
import * as MUTATIONS from "@users/domain/services/user.mutations";

export interface Locale {
  lang: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private translations: Map<string, Locale> = new Map();
  private _currentLanguage: string | null;

  constructor(private userService: UserService, private graphqlService: GraphqlService, private translateService: TranslateService, private httpClient: HttpClient) {
    this._currentLanguage = null;
  }

  public get currentLanguage(): string {
    if (!this._currentLanguage) {
      const segments = window.location.pathname.split('/');
      this._currentLanguage = segments && segments[1] ? segments[1] : Config.defaultLanguage;
    }
    return this._currentLanguage;
  }


  public async changeLanguage(lang: string, userId: string): Promise<boolean> {
    try {
      const data = (await this.graphqlService.mutate(MUTATIONS.changeLang, { lang, userId }).toPromise()) as any;
      return data.changeLang;
    } catch (error) {
      return false;
    }
  }

  public resetCurrentLanguage(): void {
    this._currentLanguage = null;
  }

  public async loadTranslations(locale: string): Promise<void> {
    if (!this.translations.has(locale)) {
      const data = await this.httpClient
        .get(document.location.protocol + '//' + document.location.hostname + environment.localeUrl.replace('{{lang}}', locale))
        .toPromise();
      this.translations.set(locale, (data as any).locale as Locale);
      this.setTranslations(this.translations.get(locale) as Locale);
    }
    this.translateService.use(locale);
  }

  public setTranslations(...args: Locale[]): void {
    [...args].forEach(locale => {
      this.translateService.setTranslation(locale.lang, locale.data, true);
    });
  }

  public async getLanguages(): Promise<string[]> {
    //if (!localStorage.getItem('langs')) {
      const data = (await this.graphqlService
        .query(this.languagesQuery, {})
        .pipe(take(1))
        .toPromise()) as any;
      const languages = await data.data.languages.items.map((x: any) => {
        return x.key;
      });
      localStorage.setItem('langs', languages.toString());
    //}
    let langs: string[] = (localStorage.getItem('langs') as any).split(',') || [];
    langs = this.orderLangsByPresentationOrder(langs);
    return langs;
  }

  private orderLangsByPresentationOrder(langs: string[]): string[] {
    const langsToSort = langs.slice();
    let sortedLangs: string[] = [];
    environment.langsPresentationOrder.forEach((langPresOrder: string) => {
      const langToSortCrtIndex = langsToSort.findIndex(langToSort => {
        return langToSort === langPresOrder;
      });
      if (langToSortCrtIndex === 0 || langToSortCrtIndex > 0) {
        sortedLangs.push(langPresOrder);
        langsToSort.splice(langToSortCrtIndex, 1);
      }
    });
    if (langsToSort.length) {
      sortedLangs = sortedLangs.concat(langsToSort);
    }

    return sortedLangs;
  }

  public async getLanguageNames(): Promise<{}> {
    const langsNames: { [langKey: string]: string } = {};
    const data = (await this.graphqlService.query(this.languagesQuery, {}).toPromise()) as any;
    data.languages.items.forEach((language: any) => {
      langsNames[language.key] = language.name;
    });
    return langsNames;
  }

  private get languagesQuery(): any {
    return gql`
      query languages {
        languages {
          items {
            _id
            key
            name
          }
        }
      }
    `;
  }
}
