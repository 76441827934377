import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LanguageService} from '@shared/services/language.service';
import {Config} from 'src/app/config';
import {ProfileService} from "@shared/services/profile.service";
import {BannerService} from "@shared/components/banner/banner.service";
import {BannerTypes} from "@shared/shared.enums";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {
  public avalabileLanguages: string[];

  private _currentLanguage: string;

  constructor(
    private translateService: TranslateService,
    private bannerService: BannerService,
    private languageService: LanguageService,
    private router: Router,
    private profileService: ProfileService) {}

  public trInstant: (key: string | Array<string>, interpolateParams?: Object) => string | any;

  public async ngOnInit(): Promise<void> {
    this.avalabileLanguages = await this.languageService.getLanguages();

    this.trInstant = this.translateService.instant;
    this.trInstant = this.trInstant.bind(this.translateService);
    if (localStorage.getItem('defaultLanguage')) {
      localStorage.setItem('defaultLanguage', localStorage.getItem('defaultLanguage'))

    } else {
      localStorage.setItem('defaultLanguage', Config.defaultLanguage)
    }
  }

  public async setLanguage(language: string): Promise<void> {
    const route = this.router.url
      .split('/')
      .splice(2, this.router.url.length)
      .join('/');
    const url = route === '' ? `/${language}` : `/${language}/${route}`;
    localStorage.setItem('defaultLanguage', language);
    if (this.profileService.isAuthenticated()) {
      this.bannerService.addMessage(this.trInstant('NAVBAR.LANGUAGE_WAIT'), BannerTypes.ALERT);
      const changedLang = await this.languageService.changeLanguage(language, this.profileService.userInfo._id)
      if (changedLang) {
        await this.router.navigateByUrl(url);
        location.reload();
      } else {
        this.bannerService.addMessage(this.trInstant('NAVBAR.LANGUAGE_NOT_CHANGED'), BannerTypes.ERROR, 10000);
      }
    } else {
      await this.router.navigateByUrl(url);
      location.reload();
    }
  }

  public get currentLanguage(): string {
    if (!this._currentLanguage) {
      const segments = window.location.pathname.split('/');
      let languageSegment = segments && segments[1] ? segments[1] : null;
      if (languageSegment === 'admin') {
        languageSegment = segments && segments[2] ? segments[2] : null;
      }
      if (!languageSegment) {
        if (localStorage.getItem('defaultLanguage') !== '')
          this._currentLanguage = localStorage.getItem('defaultLanguage');
        else
          this._currentLanguage = Config.defaultLanguage;
      } else
        this._currentLanguage = languageSegment;
    }

    return this._currentLanguage;
  }
}
