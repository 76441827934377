<div
  class="user-image"
  *ngIf="userInfo?.profileImage"
  [ngStyle]="{
    'background-image': 'url(' + userInfo?.profileImage + ')',
    width,
    height
  }"
></div>
<div class="user-image" [ngStyle]="{ width, height }" *ngIf="!userInfo?.profileImage">
  <span class="image-placeholder">{{ (userInfo?.firstName)[0] + (userInfo?.lastName)[0] }}</span>
</div>
