import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CountriesService } from '@shared/services/countries.service';
import { Countries } from '@shared/models/countries';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'phone-indicatif',
  styleUrls: ['phone-indicatif.component.scss'],
  templateUrl: 'phone-indicatif.component.html'
})
export class PhoneIndicatifComponent implements OnInit {
  public countriesCodes: Countries[];
  public selectedCountryCode: Countries;

  @Output() indicatif: EventEmitter<string> = new EventEmitter<string>();
  @Output() newPhone: EventEmitter<string> = new EventEmitter<string>();

  @Input() phone: string;

  formGroup: FormGroup = new FormGroup({});
  phoneControl: FormControl = new FormControl();
  indicatifControl: FormControl = new FormControl();
  constructor(private countriesService: CountriesService) {}

  public parsePhoneNumber(phone: string): Countries {
    let found = false;
    let index = 1;
    let country: Countries;
    while (found === false && phone) {
      if (phone[0] === '+') {
        const indicatif = phone.slice(1, index + 1);
        const findCountries = this.countriesCodes.filter(c => c.code.toString() === indicatif);
        if (findCountries.length > 0) {
          found = true;
          this.phone = phone.slice(index + 1, phone.length);
          country = findCountries[0];
        } else if (index === 4) {
          break;
        }
        index++;
      } else {
        break;
      }
    }
    return country;
  }
  public async ngOnInit(): Promise<void> {

    this.formGroup = new FormGroup({
      phone: this.phoneControl,
      indicatif: this.indicatifControl
    });
    const allCountries = await this.countriesService.getAllCountries();
    this.countriesCodes = allCountries;

    const parsedPhoneNumber = this.parsePhoneNumber(this.phone);
    this.selectedCountryCode = parsedPhoneNumber ? parsedPhoneNumber : allCountries.filter(c => c.code === 33)[0];
    this.indicatifControl.patchValue(this.selectedCountryCode);
    this.phoneControl.patchValue(this.phone ? this.phone : '');


  }

  public async handleIndicatifChange() {
    this.indicatif.emit(this.selectedCountryCode.countryISO);
    this.formGroup.get('phone').patchValue('');
    this.formGroup.get('indicatif').patchValue(this.selectedCountryCode);
  }

  public async handlePhoneChange(event: any) {
    const newValue = event.target.value;
    this.formGroup.get('phone').patchValue(newValue);
    const fullPhoneNumber = '+' + this.selectedCountryCode.code + newValue;
    if (event.target.value !== '') {
      this.newPhone.emit(fullPhoneNumber);
    }
    else {
      this.newPhone.emit('');
    }
  }

}
