import { Injectable } from '@angular/core';
import { GraphqlService } from '@graphql/graphql.service';
import gql from "graphql-tag";
import {UserNotificationRulesInteraction} from "@users/domain/models/user-notification-rules-interaction";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class NotificationRulesDialogService {

  constructor(
    private graphqlService: GraphqlService,
  ) {}



  public async markNotificationRuleAsSeen(userNotificationRulesInteraction: UserNotificationRulesInteraction): Promise<boolean> {
    const input = {} as any;
    input.idNotification = userNotificationRulesInteraction.idNotification;
    input.idUser = userNotificationRulesInteraction.idUser;
    input.seen = userNotificationRulesInteraction.seen;
    input.action = userNotificationRulesInteraction.action;
    input.idEquipment = userNotificationRulesInteraction.idEquipment;
    input.idSubscription = userNotificationRulesInteraction.idSubscription;

    try {
      return await this.graphqlService.mutate(this.markNotificationRuleAsSeenMutation(), { input }).pipe(map((result: any) => {
        return result.markNotificationRuleAsSeen;
      })).toPromise();
    } catch (error) {
      console.log('ERROR => ', error);
    }
    return true;
  }

  private markNotificationRuleAsSeenMutation() {
    return gql`
    mutation markNotificationRuleAsSeen($input: UserNotificationRulesInteractionInput!) {
    markNotificationRuleAsSeen(input: $input) {
    _id
    seen
    idEquipment
    idSubscription
    dateUpdated
    }
  }`
  }
}
