import { Component, Input } from '@angular/core';
import { User } from '@users/domain/models/user';
import { UserInfo } from '@users/domain/services/user.interfaces';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent {
  @Input() public userInfo: UserInfo | User;
  @Input() public width?: string;
  @Input() public height?: string;
}
