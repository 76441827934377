export enum ColorPickerPalette {
  RED = 'red-150',
  PURPLE = 'purple-750',
  PURPLE650 = 'purple-650',
  INDIGO = 'indigo-450',
  INDIGO650 = 'indigo-650',
  BLUE = 'blue-650',
  GREEN = 'green-350',
  YELLOW = 'yellow-250',
  BLUE_GREY = 'blue-grey-550'
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum BannerTypes {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
  ALERT = 'alert'
}

export enum NotificationType {
  BANNER = 'BANNER',
  DESKTOP = 'DESKTOP'
}
