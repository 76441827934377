import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { BannerTypes } from '../../shared.enums';
import {UserNotification} from "@users/domain/models/user-notification";

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  private _subject: Subject<any> = new Subject<any>();

  get subject(): Subject<any> {
    return this._subject;
  }

  public addMessage(message: string, type: BannerTypes, duration: number = 5000, isMessage = true): void {
    setTimeout(() => {
      this._subject.next({ message, type, duration, isMessage });
    });
  }
  public addNotification(userNotifications: UserNotification[]): void {
    setTimeout(() => {
      this._subject.next({ userNotifications: userNotifications, duration: -1, isMessage: false });
    });
  }

  public clearMessages(): void {
    this._subject.unsubscribe();
  }
}
