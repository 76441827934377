import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';

import { LanguageService } from '../services/language.service';
import {TokenSessionService} from "@shared/services/token.session.service";
import {Config} from "../../config";

@Injectable({ providedIn: 'root' })
export class TranslationsResolver implements Resolve<void> {
  constructor(private languageService: LanguageService, private router: Router, private tokenSessionService: TokenSessionService) {}

  public defaultLanguage = localStorage.getItem('defaultLanguage') ? localStorage.getItem('defaultLanguage') : Config.defaultLanguage;
  public async resolve(route: ActivatedRouteSnapshot): Promise<void> {
    const languages = await this.languageService.getLanguages();
    const routeParamLang = languages.filter(f => f === route.params.lang)[0];
    if (routeParamLang)
      await this.languageService.loadTranslations(route.params.lang);
    else {
      if (this.tokenSessionService.isAuthenticated()) {
        await this.router.navigateByUrl('/' + this.defaultLanguage + '/dashboard');
      }
      else
        await this.router.navigateByUrl('/' + this.defaultLanguage + '/users/login');
    }
  }
}
