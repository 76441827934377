import { Injectable } from '@angular/core';
import { Permission } from '@users/domain/services/user.enums';
import { UserInfo } from '@users/domain/services/user.interfaces';

import { JwtHelper } from './jwt.Helper';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private _userInfo: UserInfo;

  constructor(private jwtHelper: JwtHelper) {}

  public get permissions(): Permission[] {
    return this.userInfo ? this.userInfo.permissions : [];
  }

  public get userInfo(): UserInfo {
    if (!this._userInfo) {
      this._userInfo = this.jwtHelper.decodeToken(this.getToken());
    }
    return this._userInfo;
  }

  public storeToken(token: string, local: boolean): void {
    local ? localStorage.setItem('token', token) : sessionStorage.setItem('token', token);
  }

  public getToken(): string {
    return localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
  }

  public getTokenTimeout(): number {
    return this.jwtHelper.getTokenTimeout(this.getToken());
  }

  public logoutUser(): void {
    this._userInfo = null;
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
  }

  public isAuthenticated(): boolean {
    return !!this.getToken() && this.getTokenTimeout() > 0;
  }

  public hasPermissions(permissions: Permission[], or: boolean = false): boolean {
    const filteredPermissions = permissions.filter((item: Permission) => {
      return this.permissions.indexOf(item) > -1;
    });
    return or ? filteredPermissions.length > 0 : filteredPermissions.length === permissions.length;
  }

  public isAdmin(): boolean {
    // return this.userInfo && this.userInfo.roles.includes(RoleTypes.MatelexAdmin);
    return this.permissions.includes(Permission.MatelexAdminPermission);
  }

  public resetToken(token: string): void {
    const local = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');
    local ? localStorage.setItem('token', token) : sessionStorage.setItem('token', token);
    delete this._userInfo;
  }
}
