import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'matelex-stroked-button',
  templateUrl: './stroked-buttons.component.html',
  styleUrls: ['./stroked-buttons.component.scss']
})
export class StrokedButtonsComponent implements OnInit{

  @Input() label: string;
  @Input() icon: string;
  @Input() bordered: boolean
  @Input() customColor: string
  @Input() loading: boolean;

  @Input() disabled: boolean;
  public ngOnInit() {
  }

}
