import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, Query } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {LanguageService} from "@shared/services/language.service";
import {TokenSessionService} from "@shared/services/token.session.service";

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {
  error: any;

  constructor(private apollo: Apollo, private languageService: LanguageService, private tokenSessionService: TokenSessionService) {}

  public query<T>(query: any, variables = {}, useMockApi: boolean = false): Observable<ApolloQueryResult<any>> {
    if (useMockApi) {
      return this.apollo.use('mockApiClient').watchQuery<Query>({ query, variables, fetchPolicy: 'no-cache' }).valueChanges;
    } else {
      return this.apollo.watchQuery<Query>({ query, variables, fetchPolicy: 'no-cache' }).valueChanges;
    }
  }

  public mutate<T>(mutation: any, variables = {}): Observable<ApolloQueryResult<any>> {
    return this.apollo
      .mutate<T>({
        mutation,
        variables,
        context: {lang: this.languageService.currentLanguage, user: this.tokenSessionService.userInfo}
      })
      .pipe(map((result: any) => result.data));
  }
}
