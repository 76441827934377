import { Injectable } from '@angular/core';
import { GraphqlService } from '@graphql/graphql.service';
import gql from 'graphql-tag';
import { map, take } from 'rxjs/operators';
import { FeaturesFlag } from '@shared/models/features-flag';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FeaturesFlagService {

  private featuresFlags: FeaturesFlag[] = [];
  private _featuresFlags: BehaviorSubject<FeaturesFlag[]> = new BehaviorSubject([]);
  readonly featuresFlags$: Observable<FeaturesFlag[]> = this._featuresFlags.asObservable();
  private retrieveFeaturesFlagQuery = gql`
  query retrieveFeaturesFlag($feature: Float!) {
    retrieveFeaturesFlag(feature: $feature) {
      _id
      active
      featureName
    }
  }
`;
  private getAllFeaturesFlagsQuery = gql`
  query getAllFeaturesFlags {
    getAllFeaturesFlags {
      _id
      active
      featureName
    }
  }
`;

  constructor(private graphqlService: GraphqlService, private http: HttpClient) {

  }

  public async getAllFeaturesFlags(): Promise<FeaturesFlag[]> {
    return await this.graphqlService
      .query(this.getAllFeaturesFlagsQuery, {}, false)
      .pipe(
        take(1),
        map(result => {

          const allFeaturesFlag = result.data.getAllFeaturesFlags;
          this.featuresFlags = allFeaturesFlag;
          this._featuresFlags.next(this.featuresFlags);
          return allFeaturesFlag;
        })
      )
      .toPromise() as Promise<FeaturesFlag[]>;

  }

  public getFeatureFlagByName(featureName: string): Observable<FeaturesFlag> {
    return this.featuresFlags$
      .pipe(
        map((features => {
          const featuresFiltered = features
            .filter(feature => feature.featureName.toLowerCase() === featureName.toLowerCase());
          return featuresFiltered.length > 0 ? featuresFiltered[0] : null;
        })));
  }

  public async retrieveFeaturesFlag(featureName: string): Promise<FeaturesFlag> {
    return await this.graphqlService
      .query(this.retrieveFeaturesFlagQuery, { featureName }, false)
      .pipe(
        take(1),
        map(result => {
          const featuresFlag = result.data.retrieveFeaturesFlag;
          this.featuresFlags = featuresFlag;
          console.log('FEATURES FLAG => ', featuresFlag);
          return featuresFlag;
        })
      )
      .toPromise() as Promise<FeaturesFlag>;
  }
}
