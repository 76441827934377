import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'matelex-secondary-button',
  templateUrl: './secondary-buttons.component.html',
  styleUrls: ['./secondary-buttons.component.scss']
})
export class SecondaryButtonsComponent implements OnInit {

  @Input() label: string;
  @Input() customColor: string
  @Input() icon: string;
  @Input() disabled: boolean;
  public ngOnInit() {
  }

  public onClick() {
    // this.fn.emit(this.triggerFn)
  }

}
