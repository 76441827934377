import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'matelex-primary-button',
  templateUrl: './primary-buttons.component.html',
  styleUrls: ['./primary-buttons.component.scss']
})
export class PrimaryButtonsComponent implements OnInit, OnChanges {

  @Input() label: string;
  @Input() icon: string;
  @Input() customColor: string
  @Input() disabled: boolean;
  @Input() loading: boolean;
  public ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  public onClick() {
    // this.fn.emit(this.triggerFn)
  }

}
