<div class="matelex-button">
  <button [color]="'primary'" mat-button (click)='onClick()' [disabled]='disabled'>
    <mat-icon *ngIf='icon' fontSet="material-icons-outlined">
    {{icon}}
    </mat-icon>
    <span class='text-color'
          [style]="{'color': customColor !== '' ? customColor + ' !important' : ''}"
    >{{label}}</span>
  </button>
</div>
