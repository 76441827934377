import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class MatPaginatorCustomClass extends MatPaginatorIntl {
  constructor(private translateService: TranslateService) {
    super();
    this.itemsPerPageLabel = this.translateService.instant('ADMIN.TABLE.PAGINATOR.ITEMS_PER_PAGE');
    this.nextPageLabel = this.translateService.instant('ADMIN.TABLE.PAGINATOR.NEXT_PAGE');
    this.previousPageLabel = this.translateService.instant('ADMIN.TABLE.PAGINATOR.PREVIOUS_PAGE');
  }

  public getRangeLabel: (page: number, pageSize: number, length: number) => string = (
    page,
    pageSize,
    length
  ): string => {
    length = Math.max(length, 0);
    if (length === 0 || pageSize === 0) {
      return this.translateService.instant('ADMIN.TABLE.PAGINATOR.RANGE_ZERO', { length });
    }
    const startIndex = page * pageSize + 1;
    const endIndex = Math.min(startIndex + pageSize - 1, length);
    return this.translateService.instant('ADMIN.TABLE.PAGINATOR.RANGE', { startIndex, endIndex, length });
    // eslint-disable-next-line semi, @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
  };
}
