import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
// import { Feature, View } from 'ol';
// import Point from 'ol/geom/Point';
// import TileLayer from 'ol/layer/Tile';
// import VectorLayer from 'ol/layer/Vector';
// import Map from 'ol/Map';
// import * as olProj from 'ol/proj';
// import OSM from 'ol/source/OSM';
// import VectorSource from 'ol/source/Vector';
// import Icon from 'ol/style/Icon';
// import Style from 'ol/style/Style';

import * as L from 'leaflet';
import { Markers } from './markers';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';

const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

export class AppMarkerAttributes {
  isMarker: true;
  markerColor?: string;
  [attrName: string]: any;
}

export class AppOlMapMarkerData {
  lon: string;
  lat: string;
  attributes?: AppMarkerAttributes;
}

export class AppMapClickData {
  attributes: AppMarkerAttributes;
}

export class MMarker extends L.Marker {
  private mdAttributes: any;

  constructor(latLng: L.LatLngExpression, options?: L.MarkerOptions) {
    super(latLng, options);
  }

  public getMDAttributes() {
    return this.mdAttributes;
  }

  public setMDAttributes(data: any) {
    this.mdAttributes = data;
  }
}

@Component({
  selector: 'app-ol-map',
  templateUrl: './ol-map.component.html',
  styleUrls: ['./ol-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OlMapComponent implements AfterViewInit, OnChanges {
  public map: L.Map;
  // public markersSource: VectorSource;
  private markersLayerGroup: L.LayerGroup;
  public centerDefault: string[] = ['2.318514', '46.405832'];
  public zoomDefault: number = 5;
  private defaultMarkerColor = '#0B79D0';

  @Input() public markersCoords: AppOlMapMarkerData[] = [];

  @Input() public center: string[] = this.centerDefault; // an array with longitude as 1st and latitude as 2nd element.

  @Input() public zoom: number = this.zoomDefault;

  @Input() public containerWidth: string = '100%';
  @Input() public containerHeight: string = '400px';
  @Input() public containerUniqueId: string = 'containerUniqueId_should_be_defined';

  @Output('clickOnMap') clickOnMapEE = new EventEmitter<AppMapClickData>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    // this.initMapHolderId();
    // this.initMap();

    try {
      this.initLMap();
    } catch (e) {
    }

    // this.changeDetectorRef.detach();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('OlMapComponent ngOnChanges changes', changes);
    if (changes?.markersCoords?.firstChange) {
      return;
    }
    if (changes.markersCoords) {
      this.markersLayerGroup?.clearLayers();
      this.addMarkers(changes.markersCoords.currentValue);

      //   const markers: Feature<any>[] = this.buildMarkersFeatures(changes.markersCoords.currentValue);
      //   this.markersSource.clear();
      //   this.markersSource.addFeatures(markers);
      //   this.map.updateSize();
    }
  }

  initLMap() {
    if (!this.center || !this.center[0] || !this.center[1]) {
      this.center = this.centerDefault;
      this.zoom = this.zoomDefault;
    }

    this.map = L.map(this.containerUniqueId, {
      preferCanvas: true,
      center: [parseFloat(this.center[1] as string), parseFloat(this.center[0] as string)],
      zoom: this.zoom
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    this.markersLayerGroup = L.layerGroup().addTo(this.map);

    this.addMarkers(this.markersCoords);

    this.map.on('click', (evt: L.LeafletEvent) => {
      console.log('OlMapComponent initLMap map on click evt', evt);

      this.clickOnMapEE.emit({ attributes: null });
    });
  }

  private addMarkers(markersCoords: AppOlMapMarkerData[]): void {
    console.log('OlMapComponent addMarkers markersCoords', markersCoords);

    markersCoords.forEach((markerCoords: AppOlMapMarkerData) => {
      if (!markerCoords.lat || !markerCoords.lon) {
        return;
      }
      if (isNaN(parseFloat(markerCoords.lat)) && isNaN(parseFloat(markerCoords.lon))) {
        return;
      }

      const markerColor = markerCoords.attributes.markerColor ? markerCoords.attributes.markerColor : this.defaultMarkerColor;
      const svgIcon = L.divIcon({
        html: Markers.whiteMarker.replace('###markerFillColor', markerColor).replace('###arialabel', 'dashboard-marker-' + markerCoords.lat + ';' + markerCoords.lon),
        className: '',
        iconSize: [25, 41],
        iconAnchor: [10, 28],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      });

      const pinPosition = L.latLng(parseFloat(markerCoords.lat as string), parseFloat(markerCoords.lon as string));
      console.log('PIN POSITION => ', pinPosition)
      const marker = new MMarker(pinPosition, { icon: svgIcon });
      // const marker = new MMarker(pinPosition);
      marker.setMDAttributes(markerCoords.attributes);

      marker.on('click', (evt: L.LeafletEvent) => {
        console.log('OlMapComponent addMarkers marker on click evt', evt);
        const clickedMarker: MMarker = evt.target;
        if (clickedMarker?.getMDAttributes()?.isMarker) {
          this.clickOnMapEE.emit({ attributes: clickedMarker.getMDAttributes() });
        }
      });

      this.markersLayerGroup?.addLayer(marker);
      // marker.addTo(this.map);
    });

    this.fitMapToMarkers(markersCoords);
  }

  private fitMapToMarkers(markersCoords: AppOlMapMarkerData[]): void {
    const markersLatLngs: L.LatLng[] = [];
    markersCoords.forEach((markerCoords: AppOlMapMarkerData) => {
      if (!markerCoords.lat || !markerCoords.lat) {
        return;
      }
      if (isNaN(parseFloat(markerCoords.lat)) && isNaN(parseFloat(markerCoords.lon))) {
        return;
      }
      markersLatLngs.push(L.latLng(parseFloat(markerCoords.lat as string), parseFloat(markerCoords.lon as string)));
    });

    if (markersLatLngs.length) {
      let polyline = L.polyline(markersLatLngs, { color: 'black' });

      this.map?.fitBounds(polyline.getBounds());
    }
  }

  // initMapHolderId() {
  //   let randomArray = new Uint32Array(3);
  //   window.crypto.getRandomValues(randomArray);
  //   console.log('OlMapComponent initMapHolderId randomArray', randomArray);
  // }

  // initMap() {
  //   const markers: Feature<any>[] = this.buildMarkersFeatures(this.markersCoords);

  //   this.markersSource = new VectorSource({ features: [...markers] });

  //   if (!this.center || !this.center[0] || !this.center[1]) {
  //     this.center = this.centerDefault;
  //     this.zoom = this.zoomDefault;
  //   }

  //   this.map = new Map({
  //     target: this.containerUniqueId,
  //     layers: [
  //       new TileLayer({
  //         source: new OSM()
  //       }),
  //       new VectorLayer({ source: this.markersSource })
  //     ],
  //     view: new View({
  //       center: olProj.fromLonLat([parseFloat(this.center[0] as string), parseFloat(this.center[1] as string)]),
  //       zoom: this.zoom
  //     })
  //   });

  //   this.map.on('click', (evt: any) => {
  //     // vectorSource.getFeatures().forEach(f => this.setFeatureStyle(f, [14, 20, '../../../../assets/images/red-marker.svg']));
  //     const feature = this.map.forEachFeatureAtPixel(evt.pixel, (f: any) => f);
  //     console.log('OlMapComponent initMap feature', feature);
  //     if (!feature) {
  //       this.clickOnMapEE.emit({ attributes: null });
  //       return;
  //     }

  //     const featureData = feature.get('attributes');
  //     console.log('OlMapComponent initMap featureData', featureData);

  //     if (featureData?.isMarker) {
  //       this.clickOnMapEE.emit({ attributes: featureData });
  //       // this.setFeatureStyle(feature, [20, 28, '../../../../assets/images/blue-marker.svg']);
  //       // this.selectedMarkerStore = this.storesToDisplayOnMap.find(store => store.id === featureData.storeId);
  //     }
  //   });

  //   // add cursor pointer style when you hit a marker
  //   this.map.on('pointermove', e => {
  //     const pixel = this.map.getEventPixel(e.originalEvent);
  //     const hit = this.map.hasFeatureAtPixel(pixel);
  //     this.map.getViewport().style.cursor = hit ? 'pointer' : '';
  //   });
  // }

  // private buildMarkersFeatures(markersCoords: AppOlMapMarkerData[]): Feature<any>[] {
  //   const markers: Feature<any>[] = [];

  //   markersCoords.forEach((markerCoords: AppOlMapMarkerData) => {
  //     if (!markerCoords.lat || !markerCoords.lat) {
  //       return;
  //     }

  //     const pinPosition = [parseFloat(markerCoords.lon as string), parseFloat(markerCoords.lat as string)];
  //     const marker = new Feature({
  //       geometry: new Point(olProj.fromLonLat(pinPosition)),
  //       attributes: markerCoords.attributes
  //     });

  //     const defaultMarkerColor = '#0B79D0';
  //     const markerColor = markerCoords.attributes.markerColor ? markerCoords.attributes.markerColor : defaultMarkerColor;
  //     marker.setStyle(
  //       new Style({
  //         image: new Icon({
  //           color: markerColor,
  //           crossOrigin: 'anonymous',
  //           anchor: [0.5, 1],
  //           imgSize: [20, 28],
  //           src: '../../../../assets/images/white-marker.svg'
  //         })
  //       })
  //     );

  //     markers.push(marker);
  //   });

  //   return markers;
  // }
}
