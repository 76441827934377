<div class="banner" *ngIf="messages?.length" role="alert" aria-live="assertive" id="banner">
  <div
    *ngFor="let item of messages; let i = index"
    [ngClass]="{ 'fade-out': item?.fadeState }"
    class="fade-in message-bar"
  >
    <div [ngClass]="item.type">
      <span tabindex="0">{{ item.message }}</span>
      <div class="close-box" (click)="closeBanner(i)" (keydown.enter)="closeBanner(i)" tabindex="0" aria-role="button">
        <i class="material-icons">
          close
        </i>
      </div>
    </div>
  </div>
</div>

<div class="notificationRule-container" *ngIf="finalNotificationRules?.errorNotifications?.length"  role="alert" aria-live="assertive" id="errorNotificationRule">
  <ng-container *ngIf="finalNotificationRules.errorNotifications.length > 1">
    <div class="fade-in notificationRule-bar">
      <div [ngClass]="'error notificationRule'">
        <div class="notification-rule-content row">
          <div class="row">
          <span class="notification-rule-icon">
            <mat-icon color="accent">error_outline</mat-icon>
          </span>
            <span class="notification-rule-title" tabindex="0">{{ 'NOTIFICATION.NOTIFICATIONS' | translate }}{{ 'NOTIFICATION.ERROR_NOTIFICATION_RULES' | translate }}</span>
            <span class="notification-rule-summary" *ngIf="finalNotificationRules.errorNotifications.length > 1">{{ 'NOTIFICATION.EXISTING_NOTIFICATION_RULES' | translate }}{{ 'NOTIFICATION.ERROR_NOTIFICATION_RULES' | translate }}</span>
            <span class="notification-rule-summary" *ngIf="finalNotificationRules.errorNotifications.length === 1">{{ 'NOTIFICATION.EXISTING_NOTIFICATION_RULE' | translate }}{{ 'NOTIFICATION.ERROR_NOTIFICATION_RULES' | translate }}</span>
            <span class="open-modal" (click)="openSeveralNotificationRules(finalNotificationRules.errorNotifications)">{{ 'NOTIFICATION.SEE' | translate }}</span>
            <div class="close-box-notification-rules" (click)="hideNotificationRuleBanner(finalNotificationRules.errorNotifications)" aria-role="button">
              <i class="material-icons">
                close
              </i>
            </div>
          </div>
          <div class="col-6"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="finalNotificationRules.errorNotifications.length === 1">
    <div class="fade-in notificationRule-bar">
      <div [ngClass]="'error notificationRule'">
        <div class="notification-rule-content row">
          <div class="row">
          <span class="notification-rule-icon">
            <mat-icon color="accent">check_circle_outline</mat-icon>
          </span>
            <div class='showdown-container'>
              <showdown [style]='{display: "inline-block", width: "auto"}' [options]='showdownOptions' [value]='finalNotificationRules.errorNotifications[0].title'></showdown>
              <showdown [style]='{display: "inline-block", width: "auto", marginLeft: "16px"}' [options]='showdownOptions' [value]='finalNotificationRules.errorNotifications[0].summary'></showdown>
            </div>
            <span class="open-modal" (click)="openSeveralNotificationRules(finalNotificationRules.errorNotifications)">{{ 'NOTIFICATION.SEE' | translate }}</span>
            <div class="close-box-notification-rules" (click)="hideNotificationRuleBanner(finalNotificationRules.errorNotifications)" aria-role="button">
              <i class="material-icons">
                close
              </i>
            </div>
          </div>
          <div class="col-6"></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="notificationRule-container" *ngIf="finalNotificationRules?.warningNotifications?.length"  role="alert" aria-live="assertive" id="warningNotificationRule">
  <ng-container *ngIf="finalNotificationRules.warningNotifications.length > 1">
    <div class="fade-in notificationRule-bar">
      <div [ngClass]="'alert notificationRule'">
        <div class="notification-rule-content row">
          <div class="row">
          <span class="notification-rule-icon">
            <mat-icon color="accent">error_outline</mat-icon>
          </span>
            <span class="notification-rule-title" tabindex="0">{{ 'NOTIFICATION.NOTIFICATIONS' | translate }}{{ 'NOTIFICATION.WARNING_NOTIFICATION_RULES' | translate }}</span>
            <span class="notification-rule-summary" *ngIf="finalNotificationRules.warningNotifications.length > 1">{{ 'NOTIFICATION.EXISTING_NOTIFICATION_RULES' | translate }}{{ 'NOTIFICATION.WARNING_NOTIFICATION_RULES' | translate }}</span>
            <span class="notification-rule-summary" *ngIf="finalNotificationRules.warningNotifications.length === 1">{{ 'NOTIFICATION.EXISTING_NOTIFICATION_RULE' | translate }}{{ 'NOTIFICATION.WARNING_NOTIFICATION_RULES' | translate }}</span>
            <span class="open-modal" (click)="openSeveralNotificationRules(finalNotificationRules.warningNotifications)">{{ 'NOTIFICATION.SEE' | translate }}</span>
            <div class="close-box-notification-rules" (click)="hideNotificationRuleBanner(finalNotificationRules.warningNotifications)" aria-role="button">
              <i class="material-icons">
                close
              </i>
            </div>
          </div>
          <div class="col-6"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="finalNotificationRules.warningNotifications.length === 1">
    <div class="fade-in notificationRule-bar">
      <div [ngClass]="'alert notificationRule'">
        <div class="notification-rule-content row">
          <div class="row">
          <span class="notification-rule-icon">
            <mat-icon color="accent">check_circle_outline</mat-icon>
          </span>
            <div class='showdown-container'>
              <showdown [style]='{display: "inline-block", width: "auto"}' [options]='showdownOptions' [value]='finalNotificationRules.warningNotifications[0].title'></showdown>
              <showdown [style]='{display: "inline-block", width: "auto", marginLeft: "16px"}' [options]='showdownOptions' [value]='finalNotificationRules.warningNotifications[0].summary'></showdown>
            </div>
            <span class="open-modal" (click)="openSeveralNotificationRules(finalNotificationRules.warningNotifications)">{{ 'NOTIFICATION.SEE' | translate }}</span>
            <div class="close-box-notification-rules" (click)="hideNotificationRuleBanner(finalNotificationRules.warningNotifications)" aria-role="button">
              <i class="material-icons">
                close
              </i>
            </div>
          </div>
          <div class="col-6"></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="notificationRule-container" *ngIf="finalNotificationRules?.infoNotifications?.length"  role="alert" aria-live="assertive" id="infoNotificationRule">
  <ng-container *ngIf="finalNotificationRules.infoNotifications.length > 1">
    <div class="fade-in notificationRule-bar">
      <div [ngClass]="'info notificationRule'">
        <div class="notification-rule-content row">
          <div class="row">
          <span class="notification-rule-icon">
            <mat-icon color="accent">info_outline</mat-icon>
          </span>
            <span class="notification-rule-title" tabindex="0">{{ 'NOTIFICATION.NOTIFICATIONS' | translate }}{{ 'NOTIFICATION.INFORMATION_NOTIFICATION_RULES' | translate }}</span>
            <span class="notification-rule-summary" *ngIf="finalNotificationRules.infoNotifications.length > 1">{{ 'NOTIFICATION.EXISTING_NOTIFICATION_RULES' | translate }}{{ 'NOTIFICATION.INFORMATION_NOTIFICATION_RULES' | translate }}</span>
            <span class="notification-rule-summary" *ngIf="finalNotificationRules.infoNotifications.length === 1">{{ 'NOTIFICATION.EXISTING_NOTIFICATION_RULE' | translate }}{{ 'NOTIFICATION.INFORMATION_NOTIFICATION_RULES' | translate }}</span>
            <span class="open-modal" (click)="openSeveralNotificationRules(finalNotificationRules.infoNotifications)">{{ 'NOTIFICATION.SEE' | translate }}</span>
            <div class="close-box-notification-rules" (click)="hideNotificationRuleBanner(finalNotificationRules.infoNotifications)" aria-role="button">
              <i class="material-icons">
                close
              </i>
            </div>
          </div>
          <div class="col-6"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="finalNotificationRules.infoNotifications.length === 1">
    <div class="fade-in notificationRule-bar">
      <div [ngClass]="'info notificationRule'">
        <div class="notification-rule-content row">
          <div class="row">
          <span class="notification-rule-icon">
            <mat-icon color="accent">check_circle_outline</mat-icon>
          </span>
            <span class="notification-rule-title" tabindex="0">{{finalNotificationRules.infoNotifications[0].title}}</span>
            <span class="notification-rule-summary" *ngIf="finalNotificationRules.infoNotifications.length === 1">{{finalNotificationRules.infoNotifications[0].summary}}</span>
            <span class="open-modal" (click)="openSeveralNotificationRules(finalNotificationRules.infoNotifications)">{{ 'NOTIFICATION.SEE' | translate }}</span>
            <div class="close-box-notification-rules" (click)="hideNotificationRuleBanner(finalNotificationRules.infoNotifications)" aria-role="button">
              <i class="material-icons">
                close
              </i>
            </div>
          </div>
          <div class="col-6"></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="notificationRule-container" *ngIf="finalNotificationRules?.successNotifications?.length"  role="alert" aria-live="assertive" id="successNotificationRule">
  <ng-container *ngIf="finalNotificationRules.successNotifications.length > 1">
    <div class="fade-in notificationRule-bar">
      <div [ngClass]="'success notificationRule'">
        <div class="notification-rule-content row">
          <div class="row">
          <span class="notification-rule-icon">
            <mat-icon color="accent">check_circle_outline</mat-icon>
          </span>
            <span class="notification-rule-title" tabindex="0">{{ 'NOTIFICATION.NOTIFICATIONS' | translate }}{{ 'NOTIFICATION.SUCCESS_NOTIFICATION_RULES' | translate }}</span>
            <span class="notification-rule-summary" *ngIf="finalNotificationRules.successNotifications.length > 1">{{ 'NOTIFICATION.EXISTING_NOTIFICATION_RULES' | translate }}{{ 'NOTIFICATION.SUCCESS_NOTIFICATION_RULES' | translate }}</span>
            <span class="notification-rule-summary" *ngIf="finalNotificationRules.successNotifications.length === 1">{{ 'NOTIFICATION.EXISTING_NOTIFICATION_RULE' | translate }}{{ 'NOTIFICATION.SUCCESS_NOTIFICATION_RULES' | translate }}</span>
            <span class="open-modal" (click)="openSeveralNotificationRules(finalNotificationRules.successNotifications)">{{ 'NOTIFICATION.SEE' | translate }}</span>
            <div class="close-box-notification-rules" (click)="hideNotificationRuleBanner(finalNotificationRules.successNotifications)" aria-role="button">
              <i class="material-icons">
                close
              </i>
            </div>
          </div>
          <div class="col-6"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="finalNotificationRules.successNotifications.length === 1">
    <div class="fade-in notificationRule-bar">
      <div [ngClass]="'success notificationRule'">
        <div class="notification-rule-content row">
          <div class="row">
          <span class="notification-rule-icon">
            <mat-icon color="accent">check_circle_outline</mat-icon>
          </span>
            <span class="notification-rule-title" tabindex="0">{{finalNotificationRules.successNotifications[0].title}}</span>
            <span class="notification-rule-summary" *ngIf="finalNotificationRules.successNotifications.length === 1">{{finalNotificationRules.successNotifications[0].summary}}</span>
            <span class="open-modal" (click)="openSeveralNotificationRules(finalNotificationRules.successNotifications)">{{ 'NOTIFICATION.SEE' | translate }}</span>
            <div class="close-box-notification-rules" (click)="hideNotificationRuleBanner(finalNotificationRules.successNotifications)" aria-role="button">
              <i class="material-icons">
                close
              </i>
            </div>
          </div>
          <div class="col-6"></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

